
import { getPatientDisplay } from '@/helpers';
import { defineComponent } from '@vue/runtime-core';
import { getAuth, signOut } from '@firebase/auth';

export default defineComponent({
    computed: {
        patientIndicator(){
            const { patient } = this.$store.state;
            if(!patient)
                return {};

            let pageTitle = this.$route.meta.pageTitle as string;
            if(pageTitle.includes('{questionnaire_name}')){
                const { questionnaireId } = this.$route.params;
                const questionnaire = patient.questionnaires.find(q => q.id === questionnaireId);
                pageTitle = pageTitle.replace('{questionnaire_name}', questionnaire?.name || '');
            }
            return { patient: getPatientDisplay(patient), title: pageTitle };
        }
    },
    methods: {
        logout(){
            if(this.$firebase){ // FOR STUDY
                signOut(getAuth());
                return this.$router.replace('/');
            }
            
            this.$auth0.logout({ returnTo: window.location.origin });
        }
    }
});
