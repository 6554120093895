<template>
    <slot name='toggle' v-bind='{ toggle, isActive, classes: { "collapsibleToggle": true, isActive, withToggleArrow: withArrow } }'></slot>
    <div class='collapsibleContainer' :style='{ transition, height }' ref='container'>
        <slot></slot>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from "@vue/runtime-core"

type data = { isActive: boolean, height: string, transition: string | null, setToAuto: number | null }

export default defineComponent({
    emits: ['toggled'],
    props: {
        tagName: { type: String, default: 'div' },
        active: { type: Boolean, default: false },
        transitionLength: { type: Number, default: 0.6 },
        recalculate: { type: Number, default: 0 },
        withArrow: { type: Boolean, default: true }
    },
    data: (): data => ({ isActive: false, height: '0', transition: null, setToAuto: null }),
    watch: {
        active(isActive){
            this.isActive = isActive;
        },
        recalculate(){
            this.setHeight();
        }
    },
    beforeMount(){
        this.isActive = this.active;
    },
    mounted(){
        this.setHeight();
        setTimeout(this.setTransition, 0);
    },
    methods: {
        toggle(){
            if(this.setToAuto){
                clearTimeout(this.setToAuto);
                this.setToAuto = null;
            }
            this.isActive  =!this.isActive;
            this.$emit('toggled', this.isActive);
            this.setHeight();
        },
        setTransition(){
            this.transition = `height ${this.transitionLength}s`;
        },
        setHeight(){
            const container = this.$refs.container as { children: HTMLElement[] };
            const contentHeight = container.children[0].offsetHeight;
            this.height = contentHeight + 'px';
            if(this.isActive)
                this.setToAuto = setTimeout(() => this.height = 'auto', this.transitionLength * 1000) as unknown as number;
            else
                requestAnimationFrame(() => requestAnimationFrame(() => this.height = '0'));
        }
    }
});
</script>

<style lang="scss">
.collapsibleToggle {
    display: flex;
    cursor: pointer;
    
    &.withToggleArrow::after {
        content: '◀';
        font-size: 0.7rem;
        display: inline-block;
        margin-right: .7rem;
        transition: .5s;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(1px);
    }
    &.withToggleArrow.isActive::after {
        transform: rotate(-90deg);
    }
}

.collapsibleContainer {
    overflow: hidden;
}
</style>