import store from "@/store";
import { State } from "@/ts/state/State";
import { ActionContext } from "vuex";

type Mutation<PayloadType> = (state: any, payload: PayloadType) => void

export const toCommit = <PayloadType>(func: Mutation<PayloadType>, name?: string, namespace: string = 'root' ) => {
    if(!name) name = func.name;
    const prefix = namespace !== 'root' ? `${namespace}/` : '';
    return (payload?: PayloadType) => store.commit(prefix + name, payload);
}

export const namespacedToCommit = (namespace: string) => <PayloadType>(func: Mutation<PayloadType>, name?: string) => toCommit(func, name, namespace)

type Action<LocalState, PayloadType> = (context: ActionContext<LocalState, State>, payload: PayloadType) => void

export const toDispatch = <LocalState, PayloadType>(func: Action<LocalState, PayloadType>, name?: string, namespace: string = 'root' ) => {
    if(!name) name = func.name;
    const prefix = namespace !== 'root' ? `${namespace}/` : '';
    return (payload?: PayloadType) => store.dispatch(prefix + name, payload);
}

export const namespacedToDispatch = (namespace: string) => (
    <LocalState, PayloadType>(func: Action<LocalState, PayloadType>, name?: string) => toDispatch(func, name, namespace)
)