
import CustomPopper, { popperOptions } from "@/CustomPopper";
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
    props: {
        placement: { type: String as PropType<popperOptions['placement']> },
        offset: { type: Array as PropType<popperOptions['offset']> },
        backgroundColor: { type: String, default: '#333' },
        color: { type: String, default: '#fff' },
        maxWidth: { type: String, default: '20rem' },
        delay: { type: Number, default: 400 },
        tooltipEl: { type: [String, Object] as PropType<string | HTMLElement> }
    },
    emits: ['show', 'hide'],
    data: (): { timeout: any } => ({ timeout: null }),
    methods: {
        show({ target: button }: Event){
            const tooltip = this.tooltipEl ?? this.$refs.tooltip as HTMLElement;
            if(!tooltip) return;

            const { placement, offset } = this;
            this.timeout = setTimeout(() => {
                this.$emit('show');
                CustomPopper.Show({ button: button as HTMLElement, tooltip, placement, offset });
                this.timeout = null;
            }, this.delay);
        },
        hide(){
            const tooltip = this.tooltipEl ?? this.$refs.tooltip as HTMLElement;

            if(this.timeout){
                clearTimeout(this.timeout);
                this.timeout = null;
            }else if(tooltip){
                this.$emit('hide');
                CustomPopper.Hide(tooltip);
            }
        }
    }
});
