<template>
    <sidebar-box v-if='sidebarProps' v-bind='sidebarProps'>
        <slot name='sidebar'></slot>
    </sidebar-box>
    <div :class='{ content: true, [contentClass]: Boolean(contentClass) }'>
        <header v-if='headerText'>
            <h1>
                {{ headerText }}
                <span v-if='headerInfo'>
                    <img id='chooserInfo' class='info' aria-describedby='tooltip' src='/images/info-icon.png'/>
                    <div class='tooltip' id='tooltip' role='tooltip'>
                        {{ headerInfo }}
                        <div class='tooltipArrow' data-popper-arrow></div>
                    </div>
                </span>
            </h1>
        </header>
        <section :class='sectionClass'>
            <slot></slot>
        </section>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core'
import SidebarBox from './SidebarBox.vue'

export default defineComponent({
  components: { SidebarBox },
    props: ['contentClass', 'headerText', 'headerInfo', 'sectionClass', 'sidebarProps'],
});
</script>

<style lang='scss' scoped>
.pageWrapper {
    display: contents;
}
.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
section {
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
}
</style>
