import { createApp } from '@vue/runtime-dom';

import App from './App/index.vue';
import { parser } from './helpers';
import { DateField, DatePicker, Tooltip, CustomSelect, LoadingIndicator } from '@/components';

import { ExtendedStore } from './ts/Store';
import { requestFn } from '@/ts/Request';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { FirebaseApp } from "firebase/app";
import store from './store';
import { appModes } from './appModes';



declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
      $store: ExtendedStore,
      $request: requestFn,
      $parse: typeof parser,
      $firebase: FirebaseApp
      $auth0: Auth0Client,
      appMode: appModes,
      appName: string
    }
}

import('./appModes').then(({ appMode, appNames }) => import(`@/${appMode}/main`).then(({ default: appSetup }) => {
    const { router, app } = appSetup(createApp(App));

    Object.assign(app.config.globalProperties, { $parse: parser, appMode, appName: appNames[appMode] });

    app
        .component('date-field', DateField)
        .component('custom-select', CustomSelect)
        .component('date-picker', DatePicker)
        .component('tooltip', Tooltip)
        .component('loading-indicator', LoadingIndicator)
        .use(store)
        .use(router)
        .mount('#app');
}));

