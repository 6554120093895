
import { defineComponent } from '@vue/runtime-core';
import { setToast } from '@/store';

export default defineComponent({
    props: ['text', 'submitCondition', 'submitCallback', 'to', 'failToast', 'successToast', 'immediateLoading'],
    data: () => ({ loading: false }),
    methods: {
        async submit(){
            if(this.submitCondition){
                const submitCondition = await this.submitCondition();
                if(!submitCondition){
                    if(this.failToast)
                        setToast({ text: this.failToast });
                    return;
                }
            }
            this.loading = true;
            let elapsed = 0,
                counter = setInterval(() => elapsed += 50, 50);
            await this.submitCallback();
            if(this.immediateLoading)
                this.loading = false;
            clearInterval(counter);
            setTimeout(() => {
                if(this.successToast)
                    setToast({ text: this.successToast });
                if(this.to)
                    this.$router.push(this.to);
                this.loading = false;
            }, 1000-elapsed);
        }
    }
});
