
import { dateToDashDelimited } from "@/helpers";
import { defineComponent, PropType } from "@vue/runtime-core";
import { format } from "date-fns";

export default defineComponent({
    props: {
        modelValue: { type: Date, required: true },
        label: { type: String },
        min: { type: String },
        max: { type: String },
        allowed: { type: String as PropType<'past' | 'future' | 'all'>, default: 'all' }
    },
    emits: ['update:modelValue', 'change'],
    computed: {
        computedMin(){
            if(this.min) return this.min;
            if(this.allowed === 'future') return dateToDashDelimited(new Date());

            return undefined;
        },
        dashDelimited(){
            return dateToDashDelimited(this.modelValue);
        },
        formatted(){
            return format(this.modelValue, 'dd/MM/YYY')
        }
    },
    methods: {
        onInput(event: Event){
            this.$emit('update:modelValue', new Date((event.target as HTMLInputElement).value));
            this.$emit('change');
        }
    }
})
