import { createPopper, Instance, Placement } from '@popperjs/core';

type elem = string | HTMLElement | null
export type popperOptions = {
    type?: 'box' | 'input' | 'click',
    button: elem,
    tooltip: elem,
    placement?: Placement,
    offset?: { v?: number, h?: number }
}

export default {
    instance: null as (Instance | null),
    Show: function(opt: popperOptions){
        const tooltip = this.elemize(opt.tooltip);
        const button = this.elemize(opt.button);
        if(tooltip && button)
            setTimeout(() => {
                tooltip.setAttribute('data-show', '');
                this.instance = createPopper(button, tooltip, {
                    modifiers: [{ name: 'offset', options: { offset: [opt.offset?.v ?? 0, opt.offset?.h ?? 8] } }],
                    placement: opt.placement ?? 'right',
                });
            }, 50);
    },
    Hide: function(tooltip: elem) {
        tooltip = this.elemize(tooltip);
        if(tooltip){
            tooltip.removeAttribute('data-show');
            if(this.instance){
                this.instance.destroy();
                this.instance = null;
            }
        }
    },
    events: {
        box: ['mouseenter', 'mouseleave'],
        input: ['focus', 'blur'],
        click: ['click', 'click'],
    },
    elemize(elem: elem): HTMLElement | null {
        if(typeof elem === 'string')
            return document.querySelector('#'+elem) as (HTMLElement | null)
        else
            return  elem;
    },
    initiate: function(options: popperOptions){
        const type = options.type ?? 'box';
        const showEl = options.button = this.elemize(options.button);
        const hideEl = type!=='click' ? showEl : window;
        showEl?.addEventListener(this.events[type][0], () => {
            this.Show(options);
        });
        hideEl?.addEventListener(this.events[type][1], () => this.Hide(options.tooltip));
    },
    openClickablePopper(event: Event, button: string, tooltip = 'tooltip', placement: Placement = 'bottom'){
        event.stopPropagation();
        const selector = (id: string) => document.querySelector(`#${id}`);
        const isElem = this.instance && this.instance.state.elements.reference === selector(button);
        const hide = (e: Event) => {
            const target = e.target as HTMLElement;
            if(!selector(tooltip)?.contains(target) || target.tagName === 'A'){
                this.Hide(tooltip);
                window.removeEventListener('click', hide);
            }
        }
        if(this.instance)
            hide(event);
        if(!isElem){
            this.Show({ button, tooltip, placement } as popperOptions);
            setTimeout(() => window.addEventListener('click', hide), 10);
        }
    }
}
