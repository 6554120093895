<template>
    <teleport to='body' v-if='open'>
        <dialog role='dialog' aria-labelledby='dialogTitle' aria-modal='true'>
            <div @click='$emit("close")'></div>
            <div class='card' v-bind='cardProps'>
                <h1 id='dialogTitle'><slot name='title'></slot></h1>
                <slot></slot>
            </div>
        </dialog>
    </teleport>
</template>

<script lang='ts'>
import { defineComponent } from "@vue/runtime-core";


export default defineComponent({
    props: {
        open: { type: Boolean, default: false },
        cardProps: { type: Object }
    },
    emits: ['close']
});
</script>

<style lang='scss' scoped>
dialog {
    background-color: transparent;
    opacity: 0;
    animation: fade-in ease-in .05s forwards;

    & .card {
        box-shadow: unset;
        opacity: 0;
        animation: fade-in ease-in .1s forwards;
    }
}
</style>