import { Collection } from "./Collection";
import { RawQuestionnaire } from "./Questionnaire";

export interface Question {
    id: number,
    type: 'freetext' | 'radio' | 'radioContinuous' | 'checkbox' | 'checkboxContinuous' | 'num' | 'file' | 'response' | 'objective' | 'legend' | 'date',
    text: string,
    tree: { [key: number]: [[(number | string)[]]], all?: number[] },
    cat: number | null,
    options?: string[] | { [key: number]: string, other?: true }
}

export const isQuestion = (obj: RawQuestionnaire | Collection | Question): obj is Question => 'tree' in obj;
export const isResponse = (obj: any): obj is Response => 'question_id' in obj;

export interface ChatQuestion extends Question {
    id: number,
    compoundId?: string,

    text: string,
    altVal: string | number | null,
    val: string | number | (string | number | null)[],
    selected?: { [key: string]: boolean } | number | null,
    condition: (string | number)[],
    tree_path?: string,

    allow_alt: boolean | string,
    type_specific_option?: string | null,
    iter?: number,

    link: string,
    show: boolean,
    mark?: boolean,
}

export interface Response {
    question_id: number,
    value: ChatQuestion['val'],
    tree_path?: string | null,
    created_at?: string,

    tree_top?: number,
    direct_parent?: number
}

export type submitFn = (responses: Response[]) => Promise<void>