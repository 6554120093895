<template>
    <div className='dateField'>
        <span v-for='item in Object.keys(items)' :key='item'>
            <custom-select
                v-model='items[item]'
                :options='options[item]'
                :class='{ [item]: true, error: hasError }'
                :disabled='disabled'
                :initial='{ value: 0, display: "--------" }'
            />
            <span v-if='item !== "year"' class='dateSlash'>/</span>
        </span>
    </div>
</template>

<script lang='ts'>
import { getMonth, getDate, getYear, getDaysInMonth } from 'date-fns';
import { computed, defineComponent, PropType, reactive, toRefs, watch } from '@vue/runtime-core';

type allowed = 'past' | 'future' | 'all';

export default defineComponent({
    props: {
        initialValue: { type: Date },
        initialEmpty: { type: Boolean, default: false },
        modelValue: { type: Date, required: true },
        disabled: Boolean,
        hasError: Boolean,
        allowed: { type: String as PropType<allowed>, default: 'all' },
        filterYearsOnly: { type: Boolean, default: false },
        minDate: { type: Date },
        maxDate: { type: Date },
        years: { type: Number, default: 30 }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }){
        const allowedIs = (key: allowed) => props.allowed === key;

        const makeItems = (date: Date) => ({ day: getDate(date), month: getMonth(date), year: getYear(date) })
        const items = reactive(!props.initialEmpty ? makeItems(props.initialValue ?? props.modelValue) : { day: 0, month: 0, year: 0 });
        const min = props.minDate && makeItems(props.minDate);
        const max = props.maxDate && makeItems(props.maxDate);
        const now = new Date();
        const today = makeItems(now);
        type itemsKey = keyof typeof today;

        const propsAsRefs = toRefs(props);
        watch(propsAsRefs.modelValue, value => {
            const newItems = makeItems(value as Date);

            let reassign = false;
            Object.keys(newItems).forEach((key) => reassign = items[key as itemsKey] != newItems[key as itemsKey] || reassign);
            if(reassign) Object.assign(items, newItems);
        });

        const months = new Array(12).fill(true).map((_, index) => [index, index + 1]);
        const days = new Array(31).fill(true).map((_, index) => [index + 1, index + 1]);
        const years = computed(() => {
            const amountOfYears = !max?.year || !min?.year ? props.years : max.year - min.year + 1;
            return new Array(amountOfYears).fill(true).map((_, index) => {
                if(max?.year && !min?.year)
                    return max.year - amountOfYears + index;
                else if(min?.year)
                    return min.year + index;
                
 
                switch(props.allowed){
                    case 'past':    return today.year - props.years + index + 1;
                    case 'future':  return today.year + index;
                    case 'all':     return today.year - (props.years - 20) + index + 1;
                }
            }).map(year => ([year, year]));
        });

        const options = computed(() => {
            const daysInMonth = getDaysInMonth(props.modelValue);
            const isIdentical = (key: itemsKey, ref: typeof today | undefined) => typeof ref !== 'undefined' && items[key] == ref[key];
            const testSameMonth = (ref: typeof today | undefined) => isIdentical('month', ref) && isIdentical('year', ref)
            const isSameMonth = { today: testSameMonth(today), min: testSameMonth(min), max: testSameMonth(max) }
            const isSameYear = { today: isIdentical('year', today), min: isIdentical('year', min), max: isIdentical('year', max) }

            const options = {
                day: !props.filterYearsOnly && Object.values(isSameMonth).includes(true)
                    ? isSameMonth.min
                        ? days.slice(min!.day - 1)
                        : isSameMonth.max
                            ? days.slice(0, max!.day)
                            : days.slice(allowedIs('future') ? today.day - 1 : 0, !allowedIs('past') ? daysInMonth : today.day)
                    : days,
                month: !props.filterYearsOnly && Object.values(isSameYear).includes(true)
                    ? isSameYear.min
                        ? months.slice(min!.month)
                        : isSameYear.max
                            ? months.slice(0, max!.month + 1)
                            : months.slice(allowedIs('future') ? today.month : 0, !allowedIs('past') ? 12 : today.month + 1)
                    : months,
                year: years.value
            }

            return options;
        });

        watch(items, newItems => {
            let invalid = false;
            for(let key of ['day', 'month']){
                const itemOptions = options.value[key as itemsKey];
                if(!itemOptions.find((option: number[]) => option[0] == newItems[key as itemsKey])){
                    items[key as itemsKey] = itemOptions[0][1] === 1 ? itemOptions[itemOptions.length - 1][0] : itemOptions[0][0];
                    invalid = true;
                }
            }
            
            if(!invalid) // The for loop above doesn't act as a guard as code should be able to go through all conditions
                emit('update:modelValue', new Date(newItems.year, newItems.month, newItems.day))
        }, { deep: true });

        return { items, options };
    }
});
</script>

<style lang="scss">
.dateField {
    display: flex;

    & span {
        display: flex;
        align-items: center;
    }

    & .dateSlash {
        margin: 0 0.5rem 0 0.4rem;
    }

    & select {
        width: 3.5em;
        padding: 0;
        padding-right: 0.2rem;
        min-width: unset;

        &.year {
            width: 5em;
        }
    }
}
</style>
