import { createStore } from 'vuex';
import { toCommit } from '@/helpers/store';
import { State, toast } from '@/ts/state/State';
import { UserState } from '@/ts/state/UserState';
import { ExtendedStore } from './ts/Store';

const mutations = {
    setToast(state: State, toast: toast){
        const timeout = toast.timeout || (toast.text !== null ? 4000 : 0);
        state.toast = { text: toast.text || null, timeout };
    },
    setUser: (state: State, user: Partial<UserState> = {}) => {
        user.fetched = true;
        state.user = user as UserState;
    },
    setIntaken: (state: State, intaken: boolean) => state.user.intaken = intaken,
    updateAuth0: (state: State, updates: Partial<State['auth0']>) => Object.assign(state.auth0, updates)
}

export const setToast = toCommit(mutations.setToast);
export const setUser = toCommit(mutations.setUser);
export const setIntaken = toCommit(mutations.setIntaken);
export const updateAuth0 = toCommit(mutations.updateAuth0);

const store = createStore({
    state: {
        studyPhone: '051-5597139',
        toast: { text: null, timeout: 0 } as toast,
        user: { fetched: false, intaken: false, sex: 'male', type: null, confirmed_at: null, updated_at: null },
        auth0: {
            loading: true,
            isAuthenticated: false,
            user: {},
            popupOpen: false,
            error: null,
            roles: [],
            impersonating: null
        }
    },
    mutations
});

(store as ExtendedStore).registerIfDoesNotExist = (path, module) => {
    if(!store.hasModule(path))
        store.registerModule(path, module);
}

export default store as ExtendedStore;