<template>
    <label>
        {{ label }}
        <span class='dateContainer'>
            {{ formatted }}
            <span class='material-icons-round'>event</span>
        </span>
        <input
            type='date'
            ref='input'
            :min='computedMin'
            :max='max'
            :value='dashDelimited'
            @input='onInput($event)'
        />
    </label>
</template>

<script lang="ts">
import { dateToDashDelimited } from "@/helpers";
import { defineComponent, PropType } from "@vue/runtime-core";
import { format } from "date-fns";

export default defineComponent({
    props: {
        modelValue: { type: Date, required: true },
        label: { type: String },
        min: { type: String },
        max: { type: String },
        allowed: { type: String as PropType<'past' | 'future' | 'all'>, default: 'all' }
    },
    emits: ['update:modelValue', 'change'],
    computed: {
        computedMin(){
            if(this.min) return this.min;
            if(this.allowed === 'future') return dateToDashDelimited(new Date());

            return undefined;
        },
        dashDelimited(){
            return dateToDashDelimited(this.modelValue);
        },
        formatted(){
            return format(this.modelValue, 'dd/MM/YYY')
        }
    },
    methods: {
        onInput(event: Event){
            this.$emit('update:modelValue', new Date((event.target as HTMLInputElement).value));
            this.$emit('change');
        }
    }
})
</script>

<style lang="scss" scoped>
input[type="date"] {
    position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
    content: "\25BC"; 
    color: #555;
    padding: 0 5px;
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
    color: #bf1400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
}

 /* adjust clear button */
 input[type="date"]::-webkit-clear-button {
     z-index: 1;
 }

label {
    position: relative;
    margin: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .8rem;
    font-weight: bold;
    width: fit-content;

    & .dateContainer {
        width: 100%;
        padding: .2rem .5rem;
        font-size: initial;
        font-weight: initial;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: .5rem;

        & .material-icons-round {
            font-size: 1.1rem;
            transform: translateY(-1px);
            cursor: inherit;
            opacity: .9;
        }
    }

    & input {
        position: absolute !important;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 100;
    }
}
</style>