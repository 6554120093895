<template>
    <div className='sidebarBox'>
        <slot></slot>
        <template v-if='navSpec'>
            <template v-for='section of navSpec' :key='section.id'>
                <div class='sectionTitle' v-if='section.name'>
                    <span v-if='section.icon' class='material-icons-round' :style='{ color: section.icon.color }'>
                        {{ section.icon.name }}
                    </span>
                    {{ section.name }}
                </div>
                <template v-for='link of section.links' :key='link.to ?? link.text'>
                    <component
                        :is='link.to ? "router-link" : "div"'
                        class='sectionItem'
                        :class='{ selected: link.selected && link.selected($route) }'
                        :to='link.to'
                        @click='link.onClick'
                    >
                        {{ link.text }}
                    </component>
                </template>
            </template>
        </template>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    props: {
        navSpec: Object
    }
});
</script>

<style lang="scss">
.sidebarBox {
    height: 100%;
    width: 15rem;
    position: sticky;
    top: 0;
    background-color: rgba(255, 255, 255, 0.6);
    border-left: $borderLight;
    border-radius: $corner;
    overflow-y: auto;
    flex-shrink: 0;

    @include flexbox(false, column);

    &::-webkit-scrollbar {
        width: 0.4rem;
        &-thumb {
            border-radius: $corner;
        }
    }

    & img {
        max-width: 1.5rem;
        margin-left: 0.5rem;
        filter: grayscale(50%) brightness(110%);
    }

    & [class^='material-icons'] {
        margin-left: 0.5rem;
    }

    & > * {
        border-bottom: $borderLight;
    }

    & .sectionItem {
        padding: 0.5rem 2rem;
        color: inherit;
        cursor: pointer;

        &:hover {
            background-color: $swatchB;
        }

        &.router-link-exact-active, &.selected {
            background-color: $swatchB;
        }
    }

    & .sectionTitle {
        background-color: $hoverB;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
    }
}
</style>