import { ChatQuestion, isResponse, Question, Response } from '@/ts/interfaces/Question';
import { ComponentCustomProperties } from 'vue';

const sexes = ['male', 'female', 'other'];
export function parser(this: ComponentCustomProperties | void, ...args: string[]) {
    const user = this ? this.$store.state.user : undefined;
    const sex = user?.sex ? sexes.indexOf(user.sex) : 0;

    if(args.length > 1) return args[sex >= 0 ? sex : 0];
        
    let [str] = args;
    str?.match(/\[g:([^\]]*)\]/g)?.forEach(item => {
        const split = item.slice(3, -1).split(',');
        str = str.replace(item, split[sex] ?? split[0]);
    });
    return str;
}

const addZero = (n: number) => n < 10 ? `0${n}` : n;
export const dateToDashDelimited = (date: Date) => `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;

export const getPatientDisplay = (patient: any) => patient ? patient.name || patient.email || `ID: ${patient.id}` : '';

type question = { type?: Question['type'] | '' | null };
export const isMultiChoice = (question: question) => question.type?.startsWith('radio') || question.type?.startsWith('checkbox');
export const isMultiChoiceOrNum = (question: question) => isMultiChoice(question) || question.type === 'num';

export const makeCompoundId = (obj: ChatQuestion | Response) => `${obj.tree_path ? `${obj.tree_path}/` : ''}${!isResponse(obj) ? obj.id : obj.question_id}`;

export { default as makeSendRequest } from './sendRequest';