<template>
    <svg class='collapsibleTriangle' :class='{ isActive }' viewBox="0 0 27 25" :fill='fill' xmlns="http://www.w3.org/2000/svg">
        <path d="M2.81592 10.6843L22.6614 1.51809C23.9867 0.905968 25.5 1.87395 25.5 3.33377L25.5 21.6662C25.5 23.126 23.9867 24.094 22.6614 23.4819L2.81592 14.3157C1.26742 13.6005 1.26742 11.3995 2.81592 10.6843Z" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    props: {
        isActive: { type: Boolean, required: true },
        fill: { type: String, default: 'currentColor' }
    }
})
</script>

<style lang="scss" scoped>
    .collapsibleTriangle{
        margin-right: .8rem;
        width: .7rem;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;
        --translateX: 0;
        --translateY: 1px;
        transform: translate(var(--translateX), var(--translateY));

        &.isActive {
            transform: rotate(-90deg) translate(var(--translateX), var(--translateY));
        }
    }
</style>