<template>
    <div id='toast' :class='{ visible, [toast.type]: true }'>
        {{ toast.text }}
    </div>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import { setToast } from '@/store';

export default defineComponent({
    data: () => ({ visible: false }),
    computed: {
        toast(){ return this.$store.state.toast }
    },
    watch: {
        toast(toast){
            if(toast.text !== null){
                this.visible = true;
                setTimeout(() => this.visible = false, toast.timeout);
                setTimeout(() => setToast({}), toast.timeout + 500);
            }
        }
    }
})
</script>

<style lang="scss">
#toast {
	background-color: black;
	color: #fff;
	padding: 0.5rem 1rem;
	position: absolute;
	width: fit-content;
	bottom: 2rem;
	right: 2rem;
	border-radius: $corner;
	opacity: 0;
    transition: opacity .5s;
    z-index: 3000;

	&.visible {
		opacity: 1;
	}
}
</style>
