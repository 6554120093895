
import { defineComponent } from "@vue/runtime-core";


export default defineComponent({
    props: {
        open: { type: Boolean, default: false },
        cardProps: { type: Object }
    },
    emits: ['close']
});
