import { makeRequestFn, ResponseObj } from '@/ts/Request';

const BACKEND_URL = process.env.NODE_ENV?.startsWith('dev') ? 'http://localhost:3000' : 'https://api.sharedchart.care';
const timezoneOffset = new Date().getTimezoneOffset();

class FetchError extends Error {
    constructor(target: string, obj: ResponseObj) {
        super(obj.body?.error ?? obj.body);
        const Err = Error as any;
        Err.captureStackTrace?.(this, FetchError);
    
        Object.assign(this, { name: `${target} FetchError`, status: obj.status })
    }
}

const makeSendRequest: makeRequestFn = (plugins = []) => async (target, method = 'get', body, options = {}) => {
    const { requestBody, queryParams } = handleParams(method, body);
    
    const headers = new Headers({
        'Content-Type': 'application/json',
        'X-TIMEZONE-OFFSET': timezoneOffset.toString(),
        ...options.headers
    });

    for(const plugin of plugins) await plugin(headers);

    const response = await fetch(
        `${!target.startsWith('http') ? BACKEND_URL : ''}${target}${queryParams ?? ''}`,
        { ...options, credentials: 'include', method, body: requestBody ?? undefined, headers }
    );

    let responseBody;
    try{
        responseBody = await response.text();
        const parsed = JSON.parse(responseBody);
        responseBody = parsed;
    }catch(error){} // eslint-disable-line no-empty

    const out: ResponseObj = { ok: response.ok, status: response.status, body: responseBody }
    if(!response.ok)
        throw new FetchError(target, out);
    return out;
}

function handleParams(method: string, body: any){
    if(['string', 'undefined'].includes(typeof body))
        return { requestBody: body };
    if(method !== 'get')
        return { requestBody: JSON.stringify(body) };

    let queryParams = '?';
    for(const b in body){
        if(queryParams.length > 1)
            queryParams += '&';
        queryParams+=`${b}=${typeof body[b] !== 'string' ? JSON.stringify(body[b]) : body[b]}`;
    }
    return { queryParams };
}

export default makeSendRequest