
import { computed, defineAsyncComponent, defineComponent, onBeforeMount, provide, ref } from '@vue/runtime-core';
import Toast from './Toast.vue';
import Navbar from './Navbar.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { State } from '@/ts/state/State';
import { appModes } from '@/appModes';

export default defineComponent({
    setup() {
        const router = useRouter();
        const store = useStore<State>();
        const loading = ref(true);

        const impersonateDialogOpen = ref(false);
        provide('impersonateDialogOpen', impersonateDialogOpen);
        provide('setImpersonateDialog', (value: boolean) => impersonateDialogOpen.value = value);

        onBeforeMount(async () => {
            await router.isReady();
            loading.value = false;
        });

        return { loading, appModes, isAdmin: computed(() => store.state.auth0.user?.['http://sharedchart.care/auth/roles']?.includes('Admin')) }
    },
    components: {
        Toast,
        Navbar,
        ImpersonationManager: defineAsyncComponent(() => import('./ImpersonationManager.vue'))
    }
})
