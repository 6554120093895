
import { defineComponent, PropType } from "@vue/runtime-core"

export default defineComponent({
    props: {
        modelValue: Boolean,
        size: { type: String as PropType<'small' | 'large'>, default: 'small' },
        height: Number,
        width: Number
    },
    computed: {
        computedHeight(){
            return this.height || (this.size === 'small' ? 0.8 : 1.2);
        },
        computedWidth(){
            return this.width || (this.size === 'small' ? 1.6 : 4);
        },
        circleSize(){
            return this.computedHeight - 0.4;
        }
    },
    emits: ['update:modelValue', 'change']
});
