<template>
    <select :value='modelValue' @change='onChange($event)'>
        <option :value='initial.value' disabled hidden>{{ initial.display }}</option>
        <option
            v-for='(option, key) in options'
            :value='getOptionInfo(option, key)'
            :key='key'
        >
            {{ getOptionInfo(option) }}
        </option>
    </select>
</template>

<script lang='ts'>
import { defineComponent, PropType } from "@vue/runtime-core"

type Options = PropType<Array<[string | number, any]> | { [key: string]: any }>;

export default defineComponent({
    props: {
        modelValue: { type: [String, Number], required: true },
        options: { type: Object as Options, required: true },
        initial: { type: Object, default: () => ({ value: '', display: '--------' }) }
    },
    emits: ['update:modelValue', 'change'],
    methods: {
        onChange(event: Event){
            const value = (event.target as HTMLSelectElement).value;
            this.$emit('update:modelValue', value);
            this.$emit('change', value, event);
        },
        getOptionInfo(option: Array<[string | number, any]> | any, key: string | number | null = null){
            if(Array.isArray(option))
                return option[key !== null ? 0 : 1];
            
            return key ?? option;
        }
    }
});
</script>
