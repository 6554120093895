
import { defineComponent } from '@vue/runtime-core';
import { setToast } from '@/store';

export default defineComponent({
    data: () => ({ visible: false }),
    computed: {
        toast(){ return this.$store.state.toast }
    },
    watch: {
        toast(toast){
            if(toast.text !== null){
                this.visible = true;
                setTimeout(() => this.visible = false, toast.timeout);
                setTimeout(() => setToast({}), toast.timeout + 500);
            }
        }
    }
})
