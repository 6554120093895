<template>
    <button
        class='switchButton'
        role='switch'
        type='button'
        :aria-checked='modelValue'
        @click='$emit("update:modelValue", !modelValue); $emit("change", !modelValue)'
        :style='{ height: `${computedHeight}rem`, width: `${computedWidth}rem` }'
    >
        <div
            :style='{
                height: circleSize + "rem",
                width: circleSize + "rem",
                left: modelValue ? `calc(${computedWidth}rem - ${circleSize}rem - 0.2rem)` : "0.2rem",
                transition: `left ${Math.round(computedWidth / 1.6 * 10) / 100}s`
            }'
        />
    </button>
</template>

<script lang='ts'>
import { defineComponent, PropType } from "@vue/runtime-core"

export default defineComponent({
    props: {
        modelValue: Boolean,
        size: { type: String as PropType<'small' | 'large'>, default: 'small' },
        height: Number,
        width: Number
    },
    computed: {
        computedHeight(){
            return this.height || (this.size === 'small' ? 0.8 : 1.2);
        },
        computedWidth(){
            return this.width || (this.size === 'small' ? 1.6 : 4);
        },
        circleSize(){
            return this.computedHeight - 0.4;
        }
    },
    emits: ['update:modelValue', 'change']
});
</script>

<style lang="scss">
.switchButton {
    min-width: unset !important;
    padding: 0 !important;
    box-shadow: none !important;
    margin: 0.3rem 0 0 !important;
    border-radius: 10rem !important;
    position: relative;
    cursor: pointer;
    border: none !important;

    & > div {
        background-color: #fff;
        border-radius: 10rem;
        position: absolute;
        top: 0.2rem;
    }

    &[aria-checked="true"] {
        background-color: $swatchA !important;
    }
    &[aria-checked="false"] {
        background-color: #ccc !important;
        > div {
            left: 2px;
        }
    }
}
</style>