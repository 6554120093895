<template>
    <div class='skeleton' :style='{ width, "--speed": `${speed}s` }' role='progressbar' aria-valuetext='Loading' :aria-busy='true' />
</template>

<script lang='ts'>
import { defineComponent } from "@vue/runtime-core"

export default defineComponent({
    props: {
        speed: { type: Number, default: 1.8 },
        width: { type: String, default: '80%' }
    }
});
</script>

<style lang="scss">
.skeleton {
    @include skeleton(var(--speed))
}
</style>