export enum appModes {
    CORONA_STUDY = 'CoronaStudy',
    SHARED_CHART = 'SharedChart',
    VACCINE_QUESTIONNAIRE = 'VaccineQuestionnare'
}

export const appNames = {
    [appModes.CORONA_STUDY]: 'מחקר קורונה',
    [appModes.SHARED_CHART]: 'תיק חולה משותף',
    [appModes.VACCINE_QUESTIONNAIRE]: 'שאלון חיסונים',
}

export const appMode = appModes.SHARED_CHART