<template>
    <slot aria-describedby='tooltip' @mouseenter='show($event)' @mouseleave='hide'></slot>
    <teleport to='body' v-if='!tooltipEl'>
        <div class='tooltip' role='tooltip' ref='tooltip' :style='{ color, backgroundColor, maxWidth }'>
            <slot name='tooltip'></slot>
            <div class='tooltipArrow' data-popper-arrow :style='{ color: backgroundColor }'></div>
        </div>
    </teleport>
</template>

<script lang='ts'>
import CustomPopper, { popperOptions } from "@/CustomPopper";
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
    props: {
        placement: { type: String as PropType<popperOptions['placement']> },
        offset: { type: Array as PropType<popperOptions['offset']> },
        backgroundColor: { type: String, default: '#333' },
        color: { type: String, default: '#fff' },
        maxWidth: { type: String, default: '20rem' },
        delay: { type: Number, default: 400 },
        tooltipEl: { type: [String, Object] as PropType<string | HTMLElement> }
    },
    emits: ['show', 'hide'],
    data: (): { timeout: any } => ({ timeout: null }),
    methods: {
        show({ target: button }: Event){
            const tooltip = this.tooltipEl ?? this.$refs.tooltip as HTMLElement;
            if(!tooltip) return;

            const { placement, offset } = this;
            this.timeout = setTimeout(() => {
                this.$emit('show');
                CustomPopper.Show({ button: button as HTMLElement, tooltip, placement, offset });
                this.timeout = null;
            }, this.delay);
        },
        hide(){
            const tooltip = this.tooltipEl ?? this.$refs.tooltip as HTMLElement;

            if(this.timeout){
                clearTimeout(this.timeout);
                this.timeout = null;
            }else if(tooltip){
                this.$emit('hide');
                CustomPopper.Hide(tooltip);
            }
        }
    }
});
</script>

<style lang="scss">
.tooltip {
	padding: 5px 10px;
	border-radius: $corner;
	display: none;
	z-index: 1000;
    position: relative;
    opacity: 0;
    font-size: .9rem;

	&[data-show] {
        opacity: 0;
        display: flex;
        flex-direction: column;
        animation: fade-in ease-in .1s forwards;
	}
}

@keyframes fade-in {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

.tooltipArrow, .tooltipArrow::before {
    position: absolute;
    width: .5rem;
    height: .5rem;
    z-index: -1;
}
.tooltipArrow::before {
    content: '';
    transform: rotate(45deg);
    background-color: currentColor;
}
[data-popper-placement^='top'] .tooltipArrow { bottom: -4px; }
[data-popper-placement^='bottom'] .tooltipArrow { top: -4px; }
[data-popper-placement^='left'] .tooltipArrow { right: -4px; }
[data-popper-placement^='right'] .tooltipArrow { left: -4px; }
</style>